(function() { 'use strict';
  // Const
  // -----
  window.SMALL_MOBILE_WIDTH = 360;
  window.PORTRAIT_MOBILE_WIDTH = 480;
  window.LARGE_PORTRAIT_MOBILE_WIDTH = 700;
  window.LANDSCAPE_MOBILE_WIDTH = 800;
  window.PORTRAIT_TABLET_WIDTH = 768;
  window.LANDSCAPE_TABLET_WIDTH = 980;
  window.DESKTOP_WIDTH = 1024;

  // Selectors
  // ---------
  window.$WINDOW = $(window);
  window.$DOCUMENT = $(document);
  window.$HTML = $(document.documentElement);
  window.$BODY = $(document.body);
  window.$HEADER = $('.header');
  window.$HEADER_MENU = $('.header-menu-container > ul');
  window.$MENU_CHECKBOX = $('.header-menu-checkbox');

  // Helpers
  // -------
  window.IS_MOBILE = !!navigator.userAgent.match(/(iPad)|(iPhone)|(iPod)|(android)|(webOS)|(Windows Phone)/i);
  window.IS_DESKTOP = !IS_MOBILE;
  window.IS_TOUCH_DEVICE = 'ontouchend' in document || !!navigator.msMaxTouchPoints || !!navigator.maxTouchPoints;

  $WINDOW
    .on('resize', function() {
      window.WINDOW_WIDTH = window.innerWidth || $WINDOW.width();
      window.WINDOW_HEIGHT = $WINDOW.height();
      window.HEADER_HEIGHT = $HEADER.outerHeight();
    })
    .trigger('resize');

  /**
   * @return boolean
   */
  window.IS_DESKTOP_WIDTH = function() {
    return WINDOW_WIDTH >= DESKTOP_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_TABLET_WIDTH = function() {
    return ( WINDOW_WIDTH >= PORTRAIT_TABLET_WIDTH && WINDOW_WIDTH < DESKTOP_WIDTH );
  };
  /**
   * @return boolean
   */
  window.IS_WIDTH_LESS_PORTRAIT_TABLET = function() {
    return WINDOW_WIDTH < PORTRAIT_TABLET_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_LANDSCAPE_TABLET_WIDTH = function() {
    return WINDOW_WIDTH < LANDSCAPE_TABLET_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < LANDSCAPE_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_LARGE_PORTRAIT_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < LARGE_PORTRAIT_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_PORTRAIT_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < PORTRAIT_MOBILE_WIDTH;
  };
  /**
   * @return boolean
   */
  window.IS_SMALL_MOBILE_WIDTH = function() {
    return WINDOW_WIDTH < SMALL_MOBILE_WIDTH;
  };


  // Variables
  var slickSettings = {
        autoplay: true,
        autoplaySpeed: 8000,
        prevArrow: '<div class="slick-prev"></div>',
        nextArrow: '<div class="slick-next"></div>',
        pauseOnDotsHover: true,
        customPaging: function() { return $('<div />'); },
        draggable: false
      },
      $mainRotator;


  // Masked input
  // ------------
  $('input[type="date"]').attr('type', 'text');

  // Phone
  $('input[type="tel"]').inputmask({
    regex: '\\+7 \\([0-6,9]\\d\\d\\) \\d{3}(\\-\\d{2}){2}',
    placeholder: '+7 (___) ___-__-__'
  });


  $DOCUMENT
    .ready(function() {
      $mainRotator = $('.main-rotator');

      $DOCUMENT
        .trigger('headerEvents')
        .trigger('headerMenuEvents');

      $WINDOW.on('resize', function() {
        $DOCUMENT
          .trigger('headerEvents')
          .trigger('headerMenuEvents')
          .trigger('rotatorImagesLoad');
      });

      // Header menu button
      // ------------------
      $MENU_CHECKBOX
        .on('change', function() {
          if( this.checked ) {
            $BODY.addClass('menu-is-opened');
          } else {
            $BODY.removeClass('menu-is-opened');
          }
        })
        .prop('checked', false)
        .prop('disabled', false)
        .trigger('change');


      // Main page banner rotator
      // ------------------------
      $mainRotator
        .one('init', function() {
          $DOCUMENT.trigger('rotatorImagesLoad');
        })
        .slick($.extend({}, slickSettings, {
          fade: true,
          arrows: true,
          dots: false,
          centerMode: true,
          centerPadding: '0px',
          slide: '.main-rotator-slide',
          appendArrows: '.main-rotator-arrows',
          draggable: false
        }));


      // Rooms rotator
      // -------------
      $('.rooms-rotator-container > div:first-child').slick($.extend({}, slickSettings, {
        autoplay: false,
        fade: true,
        adaptiveHeight: true,
        arrows: true,
        dots: false,
        centerMode: true,
        centerPadding: '0px',
        slide: '.item',
        appendArrows: '.rooms-rotator-arrows',
        draggable: false
      }));


      // ProductSection2 filter
      // ----------------------
      if( $('.catalog-buildings-container').length ) {
        var $catalogGrid = $('.catalog-buildings-container .catalog-buildings > div').isotope({
          itemSelector: '.item',
          layoutMode: 'fitRows',
          percentPosition: true,
          transitionDuration: '0.5s',
          containerStyle: null
        });

        $DOCUMENT.on('click.catalogBuildingsFilter', '.catalog-buildings-container .catalog-buildings-filter > div', function(e) {
          e.preventDefault();

          var $self = $(this),
              filterValue = $self.data('filter');

          if( !!filterValue ) {
            if( filterValue === 'all' ) {
              $self.addClass('active').siblings().removeClass('active');
              $catalogGrid.isotope({filter: '*'});
            } else {
              $self.toggleClass('active').siblings('[data-filter="all"]').removeClass('active');

              var $selected = $self.parent().children('.active');

              if( $selected.length ) {
                $catalogGrid.isotope({filter: $selected.map(function() { return '[data-filter="'.concat(this.getAttribute('data-filter'), '"]'); }).get().join(', ')});
              }
              else {
                $self.siblings('[data-filter="all"]').addClass('active');
                $catalogGrid.isotope({filter: '*'});
              }
            }
          }
        });
      }
    })

    // Set header positioning
    // ----------------------
    .on('headerEvents', function() {
      if( IS_DESKTOP_WIDTH() ) {
        $BODY.css('padding-top', 0);
        $HEADER.removeClass('fixed');

        if( $mainRotator.length ) {
          $HEADER.addClass('with-rotator');
          $mainRotator.find('.main-rotator-slide-code').css('padding-top', HEADER_HEIGHT);
          $('.main-rotator-callback').css('padding-top', HEADER_HEIGHT);
        }
        else {
          $HEADER.removeClass('with-rotator');
        }
      }
      else {
        $BODY.css('padding-top', HEADER_HEIGHT);
        $HEADER.addClass('fixed').removeClass('with-rotator');
        $mainRotator.find('.main-rotator-slide-code').css('padding-top', 0);
        $('.main-rotator-callback').css('padding-top', 0);
      }
    })

    // Header menu events
    // ------------------
    .on('headerMenuEvents', function() {
      if( IS_DESKTOP_WIDTH() ) {
        if( !$HEADER_MENU.hasClass('is-desktop-events') ) {
          $DOCUMENT
            .off('click.closeHeaderMenu');

          $MENU_CHECKBOX.prop('checked', false).trigger('change');

          $HEADER_MENU
            .css('top', 'auto')
            .addClass('is-desktop-events')
            .removeClass('is-mobile-events');
        }
      }
      else {
        $HEADER_MENU.css('top', HEADER_HEIGHT);

        if( !$HEADER_MENU.hasClass('is-mobile-events') ) {
          $HEADER_MENU
            .addClass('is-mobile-events')
            .removeClass('is-desktop-events');

          $DOCUMENT
            // Close header menu
            // -----------------
            .on('click.closeHeaderMenu', function(e) {
              if( !($(e.target).closest('.header-menu-checkbox').length || $(e.target).closest('.header-menu-button').length) ) {
                $MENU_CHECKBOX.prop('checked', false).trigger('change');

                e.stopPropagation();
              }
            })

            // Header menu items
            .on('click.headerMenu', '.header-menu-container > ul a', function(e) {
              e.preventDefault();

              var $lnk = $(this),
                  $elemToScroll = $($lnk.attr('href').substr($lnk.attr('href').lastIndexOf('#')));

              $WINDOW.scrollTo($elemToScroll, {duration: 150, offset: -HEADER_HEIGHT});
            });
        }
      }
    })

    // Load rotator images
    .on('rotatorImagesLoad', function() {
      if( $mainRotator.length ) {
        var dataKey = 'imageBig';

        if( IS_MOBILE_WIDTH() )
          dataKey = 'imageSmall';
        else if( !IS_DESKTOP_WIDTH() )
          dataKey = 'imageMedium';

        $mainRotator.find('.main-rotator-slide-image > div').each(function() {
          var $self = $(this),
              image = $self.data(dataKey);

          $('<img/>').attr('src', image).on('load', function() {
            $(this).remove();

            $self
              .css('background-image', 'url(\''.concat(image, '\')'))
              .addClass('no-blur');
          });
        });
      }
    })

    // Show rotator callback form
    // --------------------------
    .on('click.rotatorCallbackShow', '.rotator-callback-show-button', function(e) {
      e.preventDefault();

      $(this).parent().addClass('form-show');
    })

    // Why we block events
    .on('click.whyWeShow', '.why-we-block-container .why-we-button-show', function(e) {
      e.preventDefault();

      var $container = $('.why-we-block-container');

      $WINDOW.scrollTo($container, {
        duration: 150,
        offset: -25-(IS_DESKTOP_WIDTH() ? 0 : HEADER_HEIGHT),
        start: function() { $container.addClass('opened').children('.why-we-preview').animate({'height': '0px'}, 500); }
      });
    })
    .on('click.whyWeClose', '.why-we-block-container .why-we-button-hide, .why-we-block-container .why-we-button-hide-sticky', function(e) {
      e.preventDefault();

      var $container = $('.why-we-block-container');

      $WINDOW.scrollTo($container, {
        duration: 150,
        offset: -25-(IS_DESKTOP_WIDTH() ? 0 : HEADER_HEIGHT),
        start: function() { $container.removeClass('opened').children('.why-we-preview').css('height', 'unset'); }
      });
    })

    // Links events
    // ------------
    /*.on('click.catalogBuildingTour', '.catalog-buildings .item > div > div:first-child + div + div .catalog-building-tour', function() {
      $('#CallbackBuildingTour_buildingId').val($(this).data('buildingId'));

      $.fancybox.open({
        src: '#callback-building-tour-content',
        type: 'inline'
      })
    })*/
    .on('click.privacyLinkHandle', '.privacy-link-handle', function() {
      CALLBACKS.privacyLinkClick();
    })
    .on('click.catalogPhoneLinkHandle', '.catalog-phone-link-handle', function() {
      CALLBACKS.catalogPhoneLinkClick();
    })

    // Scroll to
    // ---------
    .on('click.jsScrollTo', '.js-scroll-to', function(e) {
      e.preventDefault();

      var $lnk = $(this),
          $elemToScroll = $($lnk.attr('href').substr($lnk.attr('href').lastIndexOf('#'))),
          speed = $lnk.data('scrollSpeed') || 150,
          offset = ($lnk.data('scrollOffset') || 0) - (IS_DESKTOP_WIDTH() ? 0 : HEADER_HEIGHT);

      $WINDOW.scrollTo($elemToScroll, {duration: speed, offset: offset});
    })

    // Tabs
    // ----
    .on('click.jsTabs', '.js-tabs a', function(e) {
      e.preventDefault();

      var $lnk = $(this),
          $destination = $($lnk.parents('.js-tabs').data('tabDest')).children().eq($lnk.parent().index());

      if( !$lnk.hasClass('active') ) {
        $lnk.addClass('active').parent().siblings().children('a').removeClass('active');
        $destination.removeClass('dn').siblings().addClass('dn');
      }

      if( !IS_DESKTOP_WIDTH() )
        $WINDOW.scrollTo($destination, {duration: 150, offset: -25-HEADER_HEIGHT});
    });


  // FancyBox
  // --------
  $.extend(true, $.fancybox.defaults, {
    autoFocus: false,
    loop: true,
    infobar: false,
    smallBtn: true,
    buttons: [
      false,
      false,
      false,
      'close'
    ],
    idleTime: false,
    transitionEffect: 'slide',
    hash: false,
    mobile: {
      clickSlide: 'close'
    },
    lang: 'ru',
    i18n: {
      ru: {
        CLOSE: 'Закрыть',
        NEXT: 'Следующий слайд',
        PREV: 'Предыдущий слайд',
        ERROR: 'Ошибка загрузки. <br/>Пожалуйста, попробуйте позже.',
        PLAY_START: 'Запустить показ слайдов',
        PLAY_STOP: 'Остановить показ слайдов',
        FULL_SCREEN: 'В полный экран',
        THUMBS: 'Миниатюры',
        DOWNLOAD: 'Скачать',
        SHARE: 'Поделиться',
        ZOOM: 'Масштаб'
      }
    },

    beforeLoad: function(widget, current) {
      switch(current.type) {
        case 'image':
          current.opts.protect = true;
          break;

        case 'inline':
          current.opts.touch = false;
          break;
      }
    },
    afterLoad: function(widget, current) {
      if( current.contentType === 'html' && !IS_MOBILE && !IS_MOBILE_WIDTH() ) {
        current.$content.find('.js-autofocus-inp').trigger('focus');
      }
    }
  });
})();